@import "../../../styles/_variables";

.connect-modal-box {
  .rodal-dialog {
    width: 415px!important;
    height: 340px!important;
    border-radius: 15px;

    @media screen and (max-width: 1024px) {
      width: 95%!important;
    }
  }
}

.connect-modal-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  width: 100%;
  height: 100%;

  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #999999;
    padding: 15px 0;

    h3{
      margin: 0;
      color: #D69700;
    }

    span{
      color: #151618;
      font-size: 25px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .main{
    margin-top: 45px;

    .blockchain-block{
      width: 100%;
      height: 65px;
      border-radius: 10px;
      margin-bottom: 25px;
      position: relative;
      cursor: pointer;


      img{
        position: absolute;
      }
    }


    .blockto{
      background-color: #0274FF;

      img{
        top: 12px;
        left: 15px;
        width: 35%;
      }
    }

    .metamask{
      background-color: #0000004D;

      img{
        top: -20px;
        left: 7px;
        width: 60%;
      }
    }
  }



}